@use "./reset" as *;
@use "./normalize" as *;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
  color: var(--text-primary);
  font-family: proximaNova, proxima-nova, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
