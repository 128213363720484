@use "@/styles/variables" as variables;
@use "@/styles/mixins" as mixins;

.satellite-app,
.satellite-widget {
  @include mixins.display-utilities;

  @each $type, $variants in variables.$colors {
    @each $variant, $color in $variants {
      --#{$type}-#{$variant}: #{$color};
    }
  }

  --toastify-color-success: var(--success-main);
  --toastify-color-warning: var(--warning-main);
  --toastify-color-error: var(--error-main);

  a:not(.wp-element-button) {
    text-decoration: none;
    cursor: pointer;

    &[href^="tel:"] {
      direction: ltr;
      unicode-bidi: embed;
    }
  }

  p {
    @include mixins.clamp(font-size, 16px, 18px);
    @include mixins.clamp(line-height, 20px, 22px);
    color: var(--text-primary);

    & + p {
      @include mixins.clamp(margin-top, 12px, 16px);
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .main {
    display: flex;
    flex-direction: column;
    @include mixins.clamp(gap, 40px, 80px);
    @include mixins.clamp(padding-bottom, 80px, 120px);

    & > div:nth-child(2) {
      margin-top: -32px;
    }
  }

  .container {
    @include mixins.container-base;

    @include mixins.lg {
      max-width: 1200px;
    }

    &.full-width-xs {
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;

      @include mixins.xs {
        @include mixins.container-base;
      }

      @include mixins.lg {
        max-width: 1200px;
      }
    }

    &.full-width-sm {
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;

      @include mixins.sm {
        @include mixins.container-base;
      }

      @include mixins.lg {
        max-width: 1200px;
      }
    }
  }

  .link-hover {
    position: relative;
    cursor: pointer;

    &:hover {
      color: var(--primary-main);
    }
  }

  .m-0 {
    margin: 0;
  }

  details {
    width: 100%;
    border-top: 1px solid var(--text-disabled);
    border-bottom: 1px solid var(--text-disabled);
    overflow: hidden;

    @include mixins.clamp(padding-top, 16px, 24px);
    @include mixins.clamp(padding-bottom, 16px, 24px);

    & + details {
      border-top: none;
    }

    summary {
      position: relative;
      display: flex;
      align-items: center;
      list-style: none;
      font-weight: 400;

      @include mixins.clamp(font-size, 18px, 22px);
      @include mixins.clamp(line-height, 22px, 27px);

      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 14px;
        height: 8px;
        display: inline-block;
        background: url(../../public/images/icons/arrow.svg) no-repeat center
          center;
        background-size: contain;
        transition: transform 0.2s ease-in-out;
      }
    }

    &[open] {
      summary::after {
        transform: rotate(-180deg);
      }
    }
  }
}
